import React, { useEffect, useState } from 'react';
import './luke.css';
import Modal from 'react-bootstrap/Modal';
import grinch from './images/grinch.png';

interface Props {
    lukeNummer: number;
    image: string;
    text: string;
    open: boolean;
    calenderName: string;
    handleLukeOpen: (lukeNummer: number) => void;
}

function Luke({
    lukeNummer,
    image,
    text,
    open,
    calenderName,
    handleLukeOpen,
}: Props) {
    const [show, setShow] = useState(false);
    const [showGrinche, setShowGrinche] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const today = new Date();
    const day = today.getDate();

    useEffect(() => {
        if (showGrinche === true) {
            setTimeout(() => {
                setShowGrinche(false);
            }, 2000);
        }
    }, [showGrinche]);

    const handleClick = () => {
        if (
            day >= lukeNummer &&
            calenderName === localStorage.getItem('navn')
        ) {
            handleLukeOpen(lukeNummer);
        } else {
            setShowGrinche(true);
        }
    };

    return (
        <div className='luke' onClick={handleClick}>
            {open ? (
                <img src={image} alt='luke bilde' onClick={handleShow} />
            ) : (
                <>
                    {showGrinche ? (
                        <img src={grinch} className='grinch' />
                    ) : (
                        <p>{lukeNummer}</p>
                    )}
                </>
            )}
            <Modal
                show={show}
                onHide={handleClose}
                size='lg'
                className='luke_modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Velkommen til luke nr. {lukeNummer}!
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='modal-body-container'>
                        <img
                            src={image}
                            alt='luke bilde'
                            onClick={handleShow}
                        />
                        <p>{text}</p>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default Luke;
