import React, { useEffect, useState } from 'react';
import { setDoc, doc, getDoc } from 'firebase/firestore';
import './App.css';
import './kalender.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Luke from './luke';
import Snowfall from 'react-snowfall';
import {
    defaultLuker,
    hallvardLuker,
    herborgLuker,
    karineLuker,
    mammaLuker,
    pappaLuker,
} from './luker';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import sigmund from './images/sigmund.png';
import santa from './images/santa.png';
import raindeer from './images/raindeer2.png';
import { lukerÅpnetDefault } from './consts';
import { db } from './firebase';

// https://colorpalettes.net/color-palette-4284/

function App() {
    const [show, setShow] = useState(true);
    const [navn, setNavn] = useState('');
    const [feilmelding, setFeilmelding] = useState('');
    const [luker, setLuker] = useState(defaultLuker);
    const godkjenteNavn = ['mamma', 'pappa', 'hallvard', 'karine', 'herborg'];
    const [lukerÅpnet, setLukerÅpnet] = useState(lukerÅpnetDefault);

    const hentÅpneLuker = async (navn: string): Promise<any> => {
        const docRef = doc(db, 'julekalender', navn);
        const document = await getDoc(docRef);
        if (document.exists()) {
            setLukerÅpnet(document.data().lukerÅpnet);
        } else {
            setLukerÅpnet(lukerÅpnetDefault);
        }
    };

    useEffect(() => {
        const storageNavn = localStorage.getItem('navn');
        if (storageNavn !== null && godkjenteNavn.includes(storageNavn)) {
            if (storageNavn === 'mamma') {
                setLuker(mammaLuker);
            } else if (storageNavn === 'pappa') {
                setLuker(pappaLuker);
            } else if (storageNavn === 'hallvard') {
                setLuker(hallvardLuker);
            } else if (storageNavn === 'karine') {
                setLuker(karineLuker);
            } else if (storageNavn === 'herborg') {
                setLuker(herborgLuker);
            }
            hentÅpneLuker(storageNavn);
            setNavn(storageNavn);
            setShow(false);
        }
    }, []);

    const validerNavn = () => {
        const mammaNavn = ['kristine', 'kristine berbom'];
        const pappaNavn = ['thomas', 'thomas berbom'];
        const navnLowerCase = navn.toLocaleLowerCase();
        if (godkjenteNavn.includes(navnLowerCase)) {
            setShow(false);
            localStorage.setItem('navn', navnLowerCase);
            if (navn === 'mamma') {
                setLuker(mammaLuker);
                hentÅpneLuker('mamma');
            } else if (navn === 'pappa') {
                setLuker(pappaLuker);
                hentÅpneLuker('pappa');
            } else if (navn === 'hallvard') {
                setLuker(hallvardLuker);
                hentÅpneLuker('hallvard');
            } else if (navn === 'karine') {
                setLuker(karineLuker);
                hentÅpneLuker('karine');
            } else if (navn === 'herborg') {
                setLuker(herborgLuker);
                hentÅpneLuker('herborg');
            }
        }
        if (mammaNavn.includes(navnLowerCase)) {
            setFeilmelding('Du er ikke Kristine, du er Mamma!');
        }
        if (pappaNavn.includes(navnLowerCase)) {
            setFeilmelding('Du er ikke Thomas, du er Pappa!');
        }
    };

    const changeCalender = (event: any) => {
        const storageNavn = event.target.value;
        if (storageNavn === 'mamma') {
            setLuker(mammaLuker);
        } else if (storageNavn === 'pappa') {
            setLuker(pappaLuker);
        } else if (storageNavn === 'hallvard') {
            setLuker(hallvardLuker);
        } else if (storageNavn === 'karine') {
            setLuker(karineLuker);
        } else if (storageNavn === 'herborg') {
            setLuker(herborgLuker);
        }
        hentÅpneLuker(storageNavn);
        setNavn(storageNavn);
        setShow(false);
    };

    const handleSendInn = () => {
        validerNavn();
    };

    const handleLukeOpen = async (lukeNummer: number) => {
        const nyeLukerÅpnet = [...lukerÅpnet];
        nyeLukerÅpnet[lukeNummer - 1] = !nyeLukerÅpnet[lukeNummer - 1];
        setLukerÅpnet(nyeLukerÅpnet);
        try {
            const docRef = await setDoc(doc(db, 'julekalender', navn), {
                lukerÅpnet: nyeLukerÅpnet,
            });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className='app'>
            <Snowfall
                style={{
                    zIndex: '-2',
                    position: 'fixed',
                    width: '100vw',
                    height: '100vh',
                }}
            />
            <div className='santa-container pc'>
                <img src={santa} alt='Santa' className='santa pc' />
            </div>
            <div className='raindeer-container pc'>
                <img src={raindeer} alt='raindeer' className='raindeer pc' />
            </div>
            <div className='header'>
                <h1>Sigmund's julekalender</h1>
                <img src={sigmund} alt='sigmund' className='pc' />
                <div className='sigmund_row'>
                    <img src={sigmund} alt='sigmund' className='mobile' />
                    <img src={sigmund} alt='sigmund' className='mobile' />
                    <img src={sigmund} alt='sigmund' className='mobile' />
                </div>
            </div>
            <div className='kalender'>
                {luker.map((luke, index) => (
                    <Luke
                        lukeNummer={luke.id}
                        image={luke.image}
                        key={luke.id}
                        text={luke.text}
                        open={lukerÅpnet[index]}
                        calenderName={navn}
                        handleLukeOpen={handleLukeOpen}
                    />
                ))}
            </div>
            <select onChange={changeCalender} value='andre'>
                <option value='andre' disabled hidden>
                    Andre kalendere
                </option>
                <option value='mamma'>Mamma</option>
                <option value='pappa'>Pappa</option>
                <option value='hallvard'>Hallvard</option>
                <option value='karine'>Karine</option>
                {localStorage.getItem('navn') === 'herborg' && (
                    <option value='herborg'>Herborg</option>
                )}
            </select>
            <Modal
                size='sm'
                aria-labelledby='contained-modal-title-vcenter'
                centered
                show={show}
            >
                <Modal.Header closeButton>
                    <Modal.Title id='contained-modal-title-vcenter'>
                        Hva heter du?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputGroup className='mb-3'>
                        <Form.Control
                            onChange={(e) =>
                                setNavn(e.target.value.toLocaleLowerCase())
                            }
                            placeholder='Navn'
                            aria-label='Navn'
                            aria-describedby='basic-addon1'
                        />
                    </InputGroup>
                    <p className='red'>{feilmelding}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleSendInn} variant='success'>
                        Send Inn
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default App;
