export const defaultLuker = [
    {
        id: 1,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Her har det skjedd en feil, last inn siden på nytt eller ta kontakt med Sigmund',
    },
    {
        id: 2,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Her har det skjedd en feil, last inn siden på nytt eller ta kontakt med Sigmund',
    },
    {
        id: 3,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Her har det skjedd en feil, last inn siden på nytt eller ta kontakt med Sigmund',
    },
    {
        id: 4,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Her har det skjedd en feil, last inn siden på nytt eller ta kontakt med Sigmund',
    },
    {
        id: 5,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Her har det skjedd en feil, last inn siden på nytt eller ta kontakt med Sigmund',
    },
    {
        id: 6,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Her har det skjedd en feil, last inn siden på nytt eller ta kontakt med Sigmund',
    },
    {
        id: 7,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Her har det skjedd en feil, last inn siden på nytt eller ta kontakt med Sigmund',
    },
    {
        id: 8,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Her har det skjedd en feil, last inn siden på nytt eller ta kontakt med Sigmund',
    },
    {
        id: 9,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Her har det skjedd en feil, last inn siden på nytt eller ta kontakt med Sigmund',
    },
    {
        id: 10,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Her har det skjedd en feil, last inn siden på nytt eller ta kontakt med Sigmund',
    },
    {
        id: 11,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Her har det skjedd en feil, last inn siden på nytt eller ta kontakt med Sigmund',
    },
    {
        id: 12,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Her har det skjedd en feil, last inn siden på nytt eller ta kontakt med Sigmund',
    },
    {
        id: 13,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Her har det skjedd en feil, last inn siden på nytt eller ta kontakt med Sigmund',
    },
    {
        id: 14,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Her har det skjedd en feil, last inn siden på nytt eller ta kontakt med Sigmund',
    },
    {
        id: 15,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Her har det skjedd en feil, last inn siden på nytt eller ta kontakt med Sigmund',
    },
    {
        id: 16,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Her har det skjedd en feil, last inn siden på nytt eller ta kontakt med Sigmund',
    },
    {
        id: 17,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Her har det skjedd en feil, last inn siden på nytt eller ta kontakt med Sigmund',
    },
    {
        id: 18,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Her har det skjedd en feil, last inn siden på nytt eller ta kontakt med Sigmund',
    },
    {
        id: 19,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Her har det skjedd en feil, last inn siden på nytt eller ta kontakt med Sigmund',
    },
    {
        id: 20,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Her har det skjedd en feil, last inn siden på nytt eller ta kontakt med Sigmund',
    },

    {
        id: 21,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Her har det skjedd en feil, last inn siden på nytt eller ta kontakt med Sigmund',
    },
    {
        id: 22,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Her har det skjedd en feil, last inn siden på nytt eller ta kontakt med Sigmund',
    },
    {
        id: 23,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Her har det skjedd en feil, last inn siden på nytt eller ta kontakt med Sigmund',
    },
    {
        id: 24,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Her har det skjedd en feil, last inn siden på nytt eller ta kontakt med Sigmund',
    },
];

export const mammaLuker = [
    {
        id: 1,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEXBJiQw3WOR6UOlxMCYGWIVseVGEHtOd7XuzWMxxvUvxUoyvFrnZwyzqBV5uZKpWgRWKrbIC4DGZgDn220dL-uknU-03QiCoHioMbIqyJohwtNwym2flfQE1B_-8PntY2yKWCrMvmlJaamyEwX-gAKEPA=w1356-h1806-no?authuser=0',
        text: "Hei Mamma! Velkommen til Sigmund's julekalender. Her vil du finne en ny luke hver dag frem til julaften. Håper du liker det!",
    },
    {
        id: 2,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEVFLhcAS7wULgI0ffCZCf8f5t33OK_rlFUwWSOb3wZ86-0nD0NWg5BXKWGYRgeEjXT5iFF7tqJ3oK9VWXnRoOLNCnAr-l-NjxJw5YAc2BxklO53BAEvb4RXAsAkwepCcN1TNukHvKeItZ_vd-T9qeboAw=w1356-h1806-no?authuser=0',
        text: 'Du har verdens beste kjæreste <3',
    },
    {
        id: 3,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEX_hm-_YxNSBAjhuVh0IIiyXbN887LqXNyTHkp__qTI_BgOPJeWw3qU7eyDgQIlE8r_8xBva5A8_QuCe89miecwCwFfy9kkJ6jh2JhWkQqbLLhoZjRZymJIfC56iwkKW2qoCK69BjWPfb4e60ZJxsSuTg=w2408-h1806-no?authuser=0',
        text: 'Du lager verdens beste frokoster! Gleder meg til å spise frokost med deg i jula!',
    },
    {
        id: 4,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEVpNO6N8jrnSEFIBkI0mn_FvCJgQMkU_xclw4-OA-NupSiZkGiQbom0zK-Ky4gr84Zlfpaiz_-XDIAhM8rq-5OpXcm5XLCUhVDA9d_lKtKXQ5TzTbff8FzBrEnSzjSfTKZme3sAg4tqLvwXdimeKUYG-A=w2408-h1806-no?authuser=0',
        text: 'Jeg liker å gå på tur med deg! I dag er jo en nydelig dag for tur. Får du lurt med pappa?',
    },
    {
        id: 5,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEXOq2TzEZxYax3WKwdZ2_u2_C5M3ym38XmaEdc-29cknzeUphZzfJNIRMAxuEPrpsbqyLRKE_-YsgY8V6r4NnelCVuyAkwqIe7BxBiVojvFU_BaPcwZu24kfqzbjSaYmmmZHintkCRUtC9fvDSqL7kxGw=w2408-h1806-no?authuser=0',
        text: 'Jeg syntes det er veldig koslig med besøk! Når du vasker badet i kollektivet blir du helt!',
    },
    {
        id: 6,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEVqnCsLs45Yyzl9Uvd0FeqFfweLzu1WOtEDtZafKY18cmLH1doTge-mH0MfjQxbEEwAG_Q90pA7toGkU3nSrme-V4nrQGRgYWLREUvw-K-vzaO28_E3KXHLkOoaSS0flOCszK4_WwMplDdRbNQqw9d3oA=w1356-h1806-no?authuser=0',
        text: 'Se så fint kjæreste du har! Han er kul selv når han spiser is!',
    },
    {
        id: 7,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEULwWgnr_L0OwuaQTSNX1u-Luq63FAW-Hut7FtGQnthauz1W2EKwrX4mJnNbjDjHiGdxv_W-XJRQRJ4JYfEJQHlRH-IjwgkneNsRiRqXhL6qJM_ohCbuzk3aR7T7nh6-DV8tMYNAIm2uvmO5y2I1N-vSg=w2408-h1806-no?authuser=0',
        text: 'Jeg liker å slappe av i sola sammen med deg!',
    },
    {
        id: 8,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEUQkIb2yWnMkZ3_P4O00rVenPMKcGuwB4V-pUscUo_r-LVQOe8uL33ChhStvVldD8vseK7N-e-rweKxH67GYf4v3OMGtjhgx2apgaQtaHoSQdKmmAceG5gA54k3DUcOnkdATS-Xyu8sm_wLKdPsonwSHA=w2408-h1806-no?authuser=0',
        text: 'Du er flinkt til å svømme!',
    },
    {
        id: 9,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEVfa51PotGPmrSXCi5GOpwvJmKXcSh3hzkLtxLQo325DUakfidLlMhjK1MLONVNuF9CtKi1y6ibxq6qaC1sw-WCDz_wjCfBdHSrJBRVe2qO7-At8EZFA_OkYZWloU8giMudY974uoRsWEevI6E-HfFq9g=w2408-h1806-no?authuser=0',
        text: 'Se på den velkomsten gjengen! Ikke rart det er så godt å komme hjem til deg!',
    },
    {
        id: 10,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEWLjRy0TCNagrx0wuCJ-djYMHavUXYC00NM_zoZRuoX1exp5QVLnV7u1XqL4QTqzmKRoK3tjEmmA0OL0J82mO4_ZMpFRhvmKKuJjsAn4vhczpob-WN2SthDq8_yJ481OmajvfkDqmxSdCky3uPbvkLMXg=s1280-no?authuser=0',
        text: 'Nå er det lov å glede seg til jul!',
    },
    {
        id: 11,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEVB7LuDydOR8Ib2gAlaU3tObc9MINvHQwgESTQzD8rtlTLsXET0SwbRmhCl5WPxi-mfV1qOfJPQaIYH3EiRNZ7RQnPYwRAsxEtz7_IegRwf4krPTdJMateSwBDnehQRYHlJ6cmVhGYox91Zgb1Y6obj8g=w720-h1280-no?authuser=0',
        text: 'Jeg tror du hadde kledd bart, kanskje du bør begynne å spare?',
    },
    {
        id: 12,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEX3INFl9LGt4X9akgmkml7f4Xbwx9t1hv7_3KPYiegNEFYv4vp0Yhgrq0ynrB5YktT9RLNUEdVx9MzNWXQ4v89TWU6QlowLL27Up9G6O56xJffJRc776Kjl38cEOeaHpFNYgLFno0VhMDkrVaZPDPSlTw=w2408-h1806-no?authuser=0',
        text: 'Tenk så mange fine turer vi har vært på sammen!',
    },
    {
        id: 13,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEXnSo3N9gMNxNugNCPszSKnzcfv9bIxKtPd68ThLVlRmvM6XaIkIsj4Ocl7_3lNYY7w6F6QMspDdYGpq9_wwLpjQX6yJtaJISdo-bYMeq4th0aOzMrHIi6tidKz_yNfqh4pBcpW30XGKMtHqLz0LUpQDA=w2408-h1806-no?authuser=0',
        text: 'Her er oss i Trondheim, jeg husker det som om det var i går!',
    },
    {
        id: 14,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEUxdxMtFWICepNIkoE_Gk5iK-8EhP41BANmhGx66gCc6UOu7mqPijxnUTB7aiQj2oHVYgdpbdfUKF4X4ST1jxnkRmXlqC4cSogStckfwcEhknP52gvOKDeONlsb3ks0H2hNqD9OCh9myUL4w6bD7wMHiw=w1080-h810-no?authuser=0',
        text: 'I dag synes jeg du skal unne deg noe godt å drikke, kanskje kokkosmelk?',
    },
    {
        id: 15,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEVTfWajv_x74pjLKCY78Qedd3lU-Wj0aXSaZV869_uWeogxa7tmLbMFYJiVn3iXC9biLU-DMqZdDBThG3SZOnCcTo74Kp_QaQP_q5pODx_HKEa1_SqJPFhSUC0NLDAamnqRbbyMQ9paFHPv3vocVTRJAw=w1016-h1806-no?authuser=0',
        text: 'Er det litt kaldt i dag? Bare å glede seg til sommeren!',
    },
    {
        id: 16,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEWD9-tdniqhQylXNspwchOqUd5gflQFwuSKetAf2bIeoQvCVrFiHHdahbljdS_YmVtHjsdk6nBO9t1c6XDSMiBPxd3sAzk0aJFK5fyhpw0NZyK8VXb8ohmNZvh7Xf9wdyfyptwo4IQPH56lAxNTxfZUww=w2408-h1806-no?authuser=0',
        text: 'Pizza i Ghana var bra, men pizza hos deg er best!',
    },
    {
        id: 17,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEUWSJkd91qA5uz6cbMw_qV5bt2hsZjBsYSHrbr3JnloxOp9lADipTZTMYvu2F1jCa0Gu7CwsXvgGYfxX9h29roZYiB-dNvNPuEx8mMKZE-Tkk_Zd5AshMjxn0qWy2fnw17t6QubkJhT4CzmLONzetqhRg=w2408-h1806-no?authuser=0',
        text: 'Se på den gjengen!',
    },
    {
        id: 18,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEUIl41Kg-GymjefV7ZMSGZsY-J4jtIv7YDbjpw8es_26UBvn_2J3DGGKkAKNhYNqfBOIVA-6BRuJ6JOo4hD81uiWD6TdS4EjwhmyN57LamDWQKKmV_AKTXnwA1m-2lvgF7M7x77kcbsCmNZabPnKW8KBA=w2408-h1806-no?authuser=0',
        text: 'Søndag, turdag?',
    },
    {
        id: 19,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEWWI4CkkRMD8R798BG8Q7FFoZAIykqkXUxF0RlcgV1gbGLiP19tGUCwQweO1qnKjvFOxTvbk42d0ENTAOB3xoy2RdR_PuJE4rgeaU5LxpQnE69GqSr2oRwuBu13EO-S_l4Gu99bMcbU01wBhUzSog-e9w=w1016-h1806-no?authuser=0',
        text: 'Se på barna dine, de er så fine!',
    },
    {
        id: 20,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEX-MPhCr6pch1NpuzQLKr7SoDUUm8UPqJCOaHHmp6o1IL7eb1njO-rTjdISzRdkta5uqz4Rq8qjco1DmnhfjrlnS2qslXDxh872tTMS89Z8pa63HMA6nbE0Bz0fNfc_9AcitsBWfePDYnihMxE_7hkWVA=w2408-h1806-no?authuser=0',
        text: 'Jeg håper du baker noe digg til jul. Du er så god til å bake!',
    },

    {
        id: 21,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEWt38ZwyI6VoacFgHYetHvq6Fw5AZper64ryDbrFsYLXji418ZE3Hip8BU3TWHkYFA0XFGVaSMi-8P4OBXihxkWvJHsO_7kFkrilbAo-u6HRv3W02U7ezdqp4UVxIiuqVnr1ZqymE6fdt8K58yzf0Ji5w=w2408-h1806-no?authuser=0',
        text: 'Du er sporty ass!',
    },
    {
        id: 22,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEXOQK9edOSoUS3RiA2hLTaJVWY9kujKTbWwEggYNw_1rlG6LLGCjLcwgfAFp7r_ztp5uhADchmYYmta2D5tFkopk4MQRSxCREo0PjwOmH-bJVVZMh3NScEqkcEfb0C3cqAnnRr6Xd3lxQVIeh-WWcc6wg=w1016-h1806-no?authuser=0',
        text: 'Er det på tide å pynte juletre?',
    },
    {
        id: 23,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEXUWDGNzVzl9y56c5wQ85HIDcGSQbaON8i6GWT4mqYpyBrp-codg6CtoR53QUIwQBmV6bMsF3hK342P_c3RutEpfHwX9PhVlBa6sXv-XZxdCNRZLvCUUElQmvWwaDpmy0ctsZgV6rpNUsEz7ZxMLMJbMw=w2408-h1806-no?authuser=0',
        text: 'I dag må vi jobbe på. Akkurat som her!',
    },
    {
        id: 24,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEWyJ0FHDFlXS5mL008squjsxYiLOi4KAMTEPtyNOsGYHI7AguRHnUQmVi910ut7uZGZqxbyjGUC1bVNIbIYJgj9_KBRFz6TlzdNGc4TCJyd3tr8tUcfB1JddG7o6zNk36zgc9qq3znpnCy71kktF6srHg=w2408-h1806-no?authuser=0',
        text: 'God jul! Vi er glad i deg!',
    },
];

export const pappaLuker = [
    {
        id: 1,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEVChr1Kku5MN9HBEyCVdlDV5gLENVw_q807uiyTlIfCDnWQz1llF4YWrvKzvoNwcnU-s47thMen5IGcN8NnWMEacTBYcaDdJGIkP_c4T3SaB3dllEczGNza4fK4z7jYyAsYgwLL5PuqYNRrVHO0UZsphg=w2408-h1806-no?authuser=0',
        text: "Hei Pappa! Velkommen til Sigmund's julekalender. Her vil du finne en ny luke hver dag frem til julaften. Håper du liker det!",
    },
    {
        id: 2,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEXjP-lx0gwDcdNjXp_anetUlq6qQrBpFolWmL5HHPjzle5r4BavjIfFhyodE3Y-sWF_E8oeD2eDhSoQjRD1toI-Wozij2-iiqZiNvx9rH_MKD3hsM_3QtS0mz3DtUhFLNI1cN3IhajGUv3r2uHh_IQ9MA=w2408-h1806-no?authuser=0',
        text: 'Jeg synes du er en fantastisk pappa. Jeg er så glad i deg!',
    },
    {
        id: 3,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEVdmFZ3Z4lsCBzBTtSGm1FNDsQQxo_NeFQ29iZ_gi9rJqUBoa-NvXjMJ4YjrtMI2HdYZ3oYklYyFVXhZzi200y4l_rWQcn8rBPO0Ow0tUZzSPbPEVflIGgDMMlEwFLY3XRZoMZWuIfK29N3GkU3dBQQfQ=w1356-h1806-no?authuser=0',
        text: 'Du er den kuleste av de kule!',
    },
    {
        id: 4,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEXTieUyKhOrRsC02WhlFlj-r3DbR1RKOTwcgvs3fkY8LUhA8KRAjH5ATP_43_uKBPbFrV1Lo6tzgdiDEu-RCy10tCbzng9Y_qYxLkYbwixp5YT_NCLbHoe-Ity2mL_xRyyVOHVVj8kqC0D8oofqfWNQmw=w2408-h1806-no?authuser=0',
        text: 'Du passer alltid på at alle har det bra!',
    },
    {
        id: 5,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEUjeA2243qq67nu8FgYIiosfhfWcF2rd1vNeDuJupjAEPpKTeIdOSNUq-dwea6CEp6gsIFLhlUkues8SwUSUpzMZoW5Mh4WEXG2cNRcWi6rVKTaTj7OaTfjQ6UiXho_9YWDyvmrlR7CNcB4kKrTn-7lBw=w1356-h1806-no?authuser=0',
        text: 'Du står alltid på og lager god mat! Uansett vær og vind.',
    },
    {
        id: 6,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEVtidNXdAbgBDk9Rehfy1cfHvavj988N2l4Obm3HhD5XseGW5H-SG72TrwWchtNfC6S9Tit6DvoFK_Xicj7zvI9XLOl-xsJknB6IlhgMulDPbPg6KauJ3eU_WlTIJNGnWWJ5M5uuYC7hCFM24HzbfmFzQ=w1600-h900-no?authuser=0',
        text: 'Jeg liker å henge med deg!',
    },
    {
        id: 7,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEVARCenLoFn7ey7cuL5hMzKHqKCgPTSwOrlIBYY-GseiJub02TTRxb-cxlU59YhvxhN0KDSQmhQ4aLEgIDvLQhiFKh25T7TSV2vDNJuHz0hn5qCLtUZi_ThfwJfBQjm6rYKb8BEuc4cYZGVq2zef1zscg=w2728-h1806-no?authuser=0',
        text: 'Du finner alltid på mye gøy! Det er kult når du er med på leken.',
    },
    {
        id: 8,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEVV6VUPw3uk4zTFfiemFEQzbqaoC5uyY1M9S5kNWfHclFjSCLhdDn6XzQ5lYnMJD6D_jSYPFab6-A8YafQC-mXX6aS3gjTDFTq13NYjrbRR00tHuwFwZH4A2DmL9zB9epd_VfWZUThRHlXZNKSXtHLFVA=w2048-h1536-no?authuser=0',
        text: 'Se på den flotte gjengen!',
    },
    {
        id: 9,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEUanPKjD8u05oScca3TX_cfZEDllNoV6DYsX-jPj9TCmNsFAJ3DI8X_3F3WQiTrsL_6iJ_8C5grSw2-QtGWYUk9v-Da9JRe2JItEXvrnB-h-9VrlVfi9BX191-l3gdU7tCVITRMXx5rvwQmcBSXapWO4w=w1356-h1806-no?authuser=0',
        text: 'Du passer alltid godt på mamma!',
    },
    {
        id: 10,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEUtEua4G2kOoGqbUm1n1Ot__DpCRps27tczAcuNausyeUb-xwHkYB5SOurT4AUne5MAPE8lfiS_mZDEkQAF42AD0e-tCmnGL3yhRVH99eH1g5tQX-lFMVo9HES5tSZlicFtRd5_DZJCvLh08RpCUKEcEQ=w1290-h1806-no?authuser=0',
        text: 'Det er mange som synes du er hot!',
    },
    {
        id: 11,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEUyNlHJO34S5uxJMnviadXOp6TUDxEu0DnOk_0Erlt5h0gBpqn4QPWpw6BoIj-HeigkgiNfr_A979B-xkMtmQv3bJjHIXbynBqAGGaEiDwmf_JNfljHGy4NX_ms8ghslezJh4TfTETu7E9vtIITxSvLuA=s960-no?authuser=0',
        text: 'Du har en kul gjeng!',
    },
    {
        id: 12,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEVFLhcAS7wULgI0ffCZCf8f5t33OK_rlFUwWSOb3wZ86-0nD0NWg5BXKWGYRgeEjXT5iFF7tqJ3oK9VWXnRoOLNCnAr-l-NjxJw5YAc2BxklO53BAEvb4RXAsAkwepCcN1TNukHvKeItZ_vd-T9qeboAw=w1356-h1806-no?authuser=0',
        text: 'Se så fin kjæreste du har!',
    },
    {
        id: 13,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEWjHg9CJWpYnjNcTsKJLUj__WHjfezoO---U-6pG3w8jbibVBQakyzt0NFSSbTMitJTu2Uz_sH2vSxoNM5s_bp8Z56PzfMXrSBkt2hyF63W_09uwTGIw_tqpxpMtic5UFYACd71KDDzjO_hoYp31ID9ag=w3212-h1806-no?authuser=0',
        text: 'Vi er glad i deg!',
    },
    {
        id: 14,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEUPMlt8wrrgHqqW32iQMo1H7Fd8yD7jaV37JddQCZ6cysfjQBinEZZsoaVPaVgrSJInU7_msJJ3pFcQJEbYLKMj5-gSEZPNrw2TBNRTZimWvrpxx9OrRT5iKZKkB4Q1_AYPzVkC-EmMQ2gbY6gQUcpXGg=w3212-h1806-no?authuser=0',
        text: 'Du er et forbilde for oss!',
    },
    {
        id: 15,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEWE_WTd8cSluzouYAdObaswpiSdYn5ClRJ_gFJWJIFgYdK7qzTO7taKMw-6O9pkMteYFEkRGJyAwDLEPqn23UguBx2zZ-7kpeCWVlpBwaxzLy3sK2mxW780BbqzvVheKzBq3xfUHeENxE8JnI7rNa1Pcw=w1356-h1806-no?authuser=0',
        text: 'Du har lært oss mye! Blant annet hvordan være morrsom!',
    },
    {
        id: 16,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEXnSo3N9gMNxNugNCPszSKnzcfv9bIxKtPd68ThLVlRmvM6XaIkIsj4Ocl7_3lNYY7w6F6QMspDdYGpq9_wwLpjQX6yJtaJISdo-bYMeq4th0aOzMrHIi6tidKz_yNfqh4pBcpW30XGKMtHqLz0LUpQDA=w2408-h1806-no?authuser=0',
        text: 'Jeg synes det er hyggelig å være med deg!',
    },
    {
        id: 17,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEVfa51PotGPmrSXCi5GOpwvJmKXcSh3hzkLtxLQo325DUakfidLlMhjK1MLONVNuF9CtKi1y6ibxq6qaC1sw-WCDz_wjCfBdHSrJBRVe2qO7-At8EZFA_OkYZWloU8giMudY974uoRsWEevI6E-HfFq9g=w2408-h1806-no?authuser=0',
        text: 'Se på den flotte gjengen! Jeg liker å komme hjem til dere!',
    },
    {
        id: 18,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEVpNO6N8jrnSEFIBkI0mn_FvCJgQMkU_xclw4-OA-NupSiZkGiQbom0zK-Ky4gr84Zlfpaiz_-XDIAhM8rq-5OpXcm5XLCUhVDA9d_lKtKXQ5TzTbff8FzBrEnSzjSfTKZme3sAg4tqLvwXdimeKUYG-A=w2408-h1806-no?authuser=0',
        text: 'Du er sporty',
    },
    {
        id: 19,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEX3INFl9LGt4X9akgmkml7f4Xbwx9t1hv7_3KPYiegNEFYv4vp0Yhgrq0ynrB5YktT9RLNUEdVx9MzNWXQ4v89TWU6QlowLL27Up9G6O56xJffJRc776Kjl38cEOeaHpFNYgLFno0VhMDkrVaZPDPSlTw=w2408-h1806-no?authuser=0',
        text: 'Vi digger når du er med på tur!',
    },
    {
        id: 20,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEVjKbGokMHYnxGHzl072OCPFAXatWJXgxL8NWANLkjuGfMXdKm8RYzaPEosANhMnS2sbFQPs7qN0W38qjbX39Ao4GgYGTiJ6EuVbSsmf006DjrzcKT2djzKEWvHj3x-DeFhCzkoV8pJRi0fqXoY3RXyMg=w2408-h1806-no?authuser=0',
        text: 'Noen ganger kan du være litt streng. Det gjelder å ikke få for mange floker i garnet ;) ',
    },

    {
        id: 21,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEUEYIFLwyCCz-FSWEkgyn_hKzUxFVarRempLua5wW4UQqXPPVqTHXllwS7pUc-UjfEz6UawKsnbiBcmMvUEHrCBeYGHFVBFJFM0_R0ksW_iRdDFWD_x52_qnyw_gGcA1F7elhmmsnKLrf5OxaCJRkl0bQ=w2728-h1806-no?authuser=0',
        text: 'Se så fine dere er!',
    },
    {
        id: 22,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEV06gXXRVGgbExHun3uF7jWXSXCb_-7b2r4pLmw2KufbmGlhmrBz_GBNCEYRbLgEiOPyIpAr0HxfCv9ckUSLQRUZJpQJlAyNi6C4225dvNgtjNeC_ZRo27I60YatNgRJzoH1DWctIvU8eWFI1W24TuTHA=w2408-h1806-no?authuser=0',
        text: 'Jeg er glad for at du har lært meg å windsurfe!',
    },
    {
        id: 23,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEXOQK9edOSoUS3RiA2hLTaJVWY9kujKTbWwEggYNw_1rlG6LLGCjLcwgfAFp7r_ztp5uhADchmYYmta2D5tFkopk4MQRSxCREo0PjwOmH-bJVVZMh3NScEqkcEfb0C3cqAnnRr6Xd3lxQVIeh-WWcc6wg=w1016-h1806-no?authuser=0',
        text: 'Har vi rukket å pynte til jul? Nå er det på tide å ta inn treet!',
    },
    {
        id: 24,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEWWI4CkkRMD8R798BG8Q7FFoZAIykqkXUxF0RlcgV1gbGLiP19tGUCwQweO1qnKjvFOxTvbk42d0ENTAOB3xoy2RdR_PuJE4rgeaU5LxpQnE69GqSr2oRwuBu13EO-S_l4Gu99bMcbU01wBhUzSog-e9w=w1016-h1806-no?authuser=0',
        text: 'Vi er glad i deg! God jul!',
    },
];

export const hallvardLuker = [
    {
        id: 1,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEVbuNMNc92jaFjyEz1rGHtQiFV30EL5LFnBA43eGRJsCtpqFrVsoJrVYlvdFTrQ7nMk0wfkABCAAkIuN0rmrwjmHsBZtwCkNNxRH3ORLkk2rET062uaxnuXgIr6OfdRiYdbEyNAtDTUG0DPshLwbUhe3w=w2408-h1806-no?authuser=0',
        text: "Hei Hallvard! Velkommen til Sigmund's julekalender. Her vil du finne en ny luke hver dag frem til julaften. Håper du liker det!",
    },
    {
        id: 2,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEUggUWqzNbRCBy_sRFprF-z43oi3JiyRVK9GAPFNGlHvHWVQnfzvWNeLNAJDITZDnIyWXwVg7rThQWcNr6F5K7SrnYrEd6r11ZxOih0BQgytB6IBfSstav_d72ET3zt1pCoCBt0wSwHAyU3Ai1hM15oiw=w1356-h1806-no?authuser=0',
        text: 'Du er kul! Jeg liker å henge med deg!',
    },
    {
        id: 3,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEVfa51PotGPmrSXCi5GOpwvJmKXcSh3hzkLtxLQo325DUakfidLlMhjK1MLONVNuF9CtKi1y6ibxq6qaC1sw-WCDz_wjCfBdHSrJBRVe2qO7-At8EZFA_OkYZWloU8giMudY974uoRsWEevI6E-HfFq9g=w2408-h1806-no?authuser=0',
        text: 'Du er snill! Jeg liker å komme hjem når dere er der og tar meg godt i mot!',
    },
    {
        id: 4,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEXr6-6INAtgq-xLaXd6ogPLm9aG0CU8fofyzZq5at6TjN_5a1MeIkuS_FI3OATQ27LPQ7Fp1v_JrUPOaeF4_Tv9Jeii9rwdqXHIEQUHNesOd6795mMguH6ddj86x6SVeK_F1ZsfVYmebNGXgBB5hBwDBw=w3212-h1806-no?authuser=0',
        text: 'Du er flink til det du gjør! Og du er et forbilde for meg!',
    },
    {
        id: 5,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEVfcftnAJTEsRg0IzYNg1vwah1A6JBL0XOdtFfLFtUKyzqdQSpQVGBLevek5Dpt73E28cEE_rW2AmVAD1prDUc8dkfFovZ9fOvy3LIIO0MM1kTF3jZcTRqJisQHZnuczNYtTdfGGPJnzVjyjtjFaJ3wxw=w1356-h1806-no?authuser=0',
        text: 'Det er godt å ha deg som megler mellom meg og Karine ;)',
    },
    {
        id: 6,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEWt38ZwyI6VoacFgHYetHvq6Fw5AZper64ryDbrFsYLXji418ZE3Hip8BU3TWHkYFA0XFGVaSMi-8P4OBXihxkWvJHsO_7kFkrilbAo-u6HRv3W02U7ezdqp4UVxIiuqVnr1ZqymE6fdt8K58yzf0Ji5w=w2408-h1806-no?authuser=0',
        text: 'Jeg liker å være med deg på tur!',
    },
    {
        id: 7,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEWWmqhzfQT6L1y3r6tRTLNZ9BIDPyxu0pIPtHkh-SmigheADqsem5hP2GiPn8aOvzLRZ5Oe9Tacq3Hipy6K13do0zxfGKO8CzaKz_7sjY_Fa0kKnX5Gn_0h0LT2OfWHw44RvQ3Qw6MH3cCNTn0v5ARlxQ=w2048-h1536-no?authuser=0',
        text: 'Du er sporty!',
    },
    {
        id: 8,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEV4WeimwbPimNVLfryq2rFPO847KOQqvDfrOGFPKtRNLjtm2FXZi14fl8ELgPcPc5dcssNeGZvZnjM2jNRnZKZcFbo-op11-Fu94xNYOFtuTs-rY2GeXuUpuvOBJ5pYkHvrULsUk7EIQjo5t-M52URqLg=w1600-h900-no?authuser=0',
        text: 'Det er morsomt å henge med deg!',
    },
    {
        id: 9,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEWzqvKkKPFZgLabzS48sZ-mSeCd1ZewVVYdi7orT_0GqCLuMRoGPnTi3o-2cMX37TVkHxaa4tlnw9coYdzwLPElWtNkvFLajLPwfy6dX13YwQD3QT_puA_ET9g19eJzh7ZUhQxX5hP5Xvv8qDeg-yLGnw=w3212-h1806-no?authuser=0',
        text: 'Du er flink til å ta vare på de rundt deg!',
    },
    {
        id: 10,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEUR9deq588s9X13KQQB0EiVs6rYPA8wZga5wrlwTMNoEQlF7g-wIq0XsaHa4k7i3TQXSewPojtQw0LW2nG-QOi-57weLLM0pkifJGaJdyTV2K-LNoDZPitCVxz8IlNKOXGe8BcReEBfkHl9oUjPn4S5Lw=w2728-h1806-no?authuser=0',
        text: 'Her er du og pappa. Begge er veldig søte!',
    },
    {
        id: 11,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEUDhf5J8hXA9HTVqjKJhQrdmva0h2KI_QNuBiic-UiPs_9nNHQ8zVG9eBHRgIFm8LFQOpjF8Nhbd16RvEJpxHQOVu_z_gzkOBHs4DbihoFJEaueEntDxhF9qCKjuifGfqx_VDs5gUYUDtbi2-Buc5VuOQ=w2408-h1806-no?authuser=0',
        text: 'Du er en god fisker!',
    },
    {
        id: 12,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEW10MFsZ5ZaDsJrGhzXgeUOw2J38DDBhp4VJgOUDWp9SjYfxg48zq7q3WmIRyzKs8bX8_wxNlGKfYgHzDBZxXVCBO3nD_NVcOjQ3AYHGD1Bb6ThKpLdQ_HWMC2qPFY1MI7LguZq45l1pcXZ_CA7sOCMZA=w960-h720-no?authuser=0',
        text: 'Du er god til å kjøre bil!',
    },
    {
        id: 13,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEUGuVIuXLyuRzc2Nayhuk47BPUti9v6nQIMf9peq6nbKbm_ihKW8jLlrv0qlGDeCCCa_C4RC7kVN8Aax2fhZcdwxuaGstfJEEdGghXXaUKb2UjBmCdH_ggO-3q56AjXGXB26NAGE-51cnCpkyrKaTZtRA=w720-h960-no?authuser=0',
        text: 'Du er snill med alle!',
    },
    {
        id: 14,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEVluiq-mxF94SJicgryRzdrzMdjCAhQBYjNjHAMk4vuZ-xSbLg6ryofT9qyppTBh94e5pE5HVZs3XY6CWqtH8VzmR2hNKk2iwGhUqv2NKqGam5RgV_sCgkaZxfzR5wauItx8nMf8d-k1121D6JWcHl0Tw=w2408-h1806-no?authuser=0',
        text: 'Du er god til å klatre!',
    },
    {
        id: 15,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEV7uGJpQeZBI75u7_WSvknu08036qoUOywcX0vKjsg_h8yMIkFFgWY5oAuk1Ens3dkneCjkRC0tGVgiwWyJiwkb6j1IvR3l448xIgygMWVYYOUqW7RbgHImp2qtIPBoMz-Lpqd08_tyOVaifXdtWZahEA=w3212-h1806-no?authuser=0',
        text: 'Du er en tullekopp!',
    },
    {
        id: 16,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEXewKlkgo0C6TEUrmuJzrOXZLkHdZ4TNA0YmH7hmgUfY4sCs7azxeTlzSz757UJtnv10GnlFNzKT_cYf2XSd_WnTKgWO-IjNXzGc2tQWPEsnstCSPiSqvGXkgseMoW9TMAV32eUCdZ4F7QKzfbdhbi45w=s843-no?authuser=0',
        text: 'Du er hvertfall ikke kjedelig! Ikke når du skal skyte med pil og bue hvertfall!',
    },
    {
        id: 17,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEXb0nxkyj7BRgHvN6GQt40dHgBOaAxlz0nLptjFyuuyJjAnLtVepp3Ir7QMtrhgdxjui8983AqWbkt2TYgktwgZgLBfrLTa6UhtJLdEF98OlXJt2gCansv-o1eupn0U5xt1byjlCCT30jY76avGV6PbTw=w1016-h1806-no?authuser=0',
        text: 'Du lager god stemming!',
    },
    {
        id: 18,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEUgQhx9VV1m3xo9olqCyb6hIcGB3uoML4BAopObA21-nCP2HrlV6cd9X9vhZZzHIw1Fkupk0BIXSfxrr0N4H8eT1i3o2M_Co-9XWYP2fuyYxXZKNRED8aMT1WKE6VEDlWX8A_UcgXqZuG1I2Komg5GLlQ=w1600-h900-no?authuser=0',
        text: 'Du er som en blomst som aldri visner!',
    },
    {
        id: 19,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEXqF-D5LDe5kF9w4G_QQ5TdEKxQ9LFu2V9iF4AyXQM1K0VVR74NCr2fEjKId7HPJfYXRYgDBhhP2pRV6FsqlXIExejIoyo4hLpTYGoPiX2a5qlzLNHEFvVLyPyho3NsProdCkq2qTYipRw0kdZiaEgdTQ=s960-no?authuser=0',
        text: 'Se på den gode gjengen din!',
    },
    {
        id: 20,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEVlwgUZiegNrFZfR7bDAgqUtYtjdYlv53YGu_nKDDgql1H5mvYPXtyAUcXQ11k1pKmIRclDeMRD9yOnEX4C6A-q96NsVyJjtVBRLIxraY9oaOU1GxvAXboLVMOLNJaPLM9qWI_Zk4k9VD2fnTGWqX-rTg=w1356-h1806-no?authuser=0',
        text: 'For en middag!',
    },

    {
        id: 21,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEUIl41Kg-GymjefV7ZMSGZsY-J4jtIv7YDbjpw8es_26UBvn_2J3DGGKkAKNhYNqfBOIVA-6BRuJ6JOo4hD81uiWD6TdS4EjwhmyN57LamDWQKKmV_AKTXnwA1m-2lvgF7M7x77kcbsCmNZabPnKW8KBA=w2408-h1806-no?authuser=0',
        text: 'Du er god å ha med på tur!',
    },
    {
        id: 22,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEWWI4CkkRMD8R798BG8Q7FFoZAIykqkXUxF0RlcgV1gbGLiP19tGUCwQweO1qnKjvFOxTvbk42d0ENTAOB3xoy2RdR_PuJE4rgeaU5LxpQnE69GqSr2oRwuBu13EO-S_l4Gu99bMcbU01wBhUzSog-e9w=w1016-h1806-no?authuser=0',
        text: 'Du er en fet fyr!',
    },
    {
        id: 23,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEWNENysLLyTM7tNHHKJfKXmw0wV7zvAtQWxT3TYLn-WxMCxg6jG5IIQR1D-X-rIfEsRJStiABneGw3DA6Ozl1ZuyVovL_mwAHubqo62vjNoFS3WORQtzB29CRUNJKLnPAoXI-IhbgRoCsw7GwmXVnuZzQ=w1016-h1806-no?authuser=0',
        text: 'Du er min brother from the same mother!',
    },
    {
        id: 24,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEWyJ0FHDFlXS5mL008squjsxYiLOi4KAMTEPtyNOsGYHI7AguRHnUQmVi910ut7uZGZqxbyjGUC1bVNIbIYJgj9_KBRFz6TlzdNGc4TCJyd3tr8tUcfB1JddG7o6zNk36zgc9qq3znpnCy71kktF6srHg=w2408-h1806-no?authuser=0',
        text: 'Jeg er glad i deg! God jul!',
    },
];

export const karineLuker = [
    {
        id: 1,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEWxMMJthAk6ZXZYfZ4QFSfahq2IgfcODWXs4OpxXqKwsxjk04IMpJhqsE5zIsCty_PTDYfpdza61V17xP1vzRCNRrSU6eueg1pL8bttMf4f104Kf0UXTAbHbL4Mt5MIryl5LZ1kgVDSZk3gTnQJRvZPMA=w2408-h1806-no?authuser=0',
        text: "Hei Karine! Velkommen til Sigmund's julekalender. Her vil du finne en ny luke hver dag frem til julaften. Håper du liker det!",
    },
    {
        id: 2,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEVdDxoC3YGlaK5g6SfR57bKtQeXpk58EQVsKeKxoBxBLN31EkUkhFD7wtemf7OALun8Lt6R4GQPlY_KIpV-E4Os6RwoUz0aEd2o3ek2m3H0m01I7_lbQyafzEcwEEWmbagpTpUBTbsI72KYSa1VbKpdoA=w834-h1806-no?authuser=0',
        text: 'Du er kul! Jeg liker å putte svette tær i ansiktet ditt!',
    },
    {
        id: 3,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEVfa51PotGPmrSXCi5GOpwvJmKXcSh3hzkLtxLQo325DUakfidLlMhjK1MLONVNuF9CtKi1y6ibxq6qaC1sw-WCDz_wjCfBdHSrJBRVe2qO7-At8EZFA_OkYZWloU8giMudY974uoRsWEevI6E-HfFq9g=w2408-h1806-no?authuser=0',
        text: 'Se på den fine gjengen! Alltid hyggelig å kommer hjem til dere. Gleder meg til du kommer hjem',
    },
    {
        id: 4,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEXSG9HeoVOxZwSsUeODtPtFVUSBtYdMOSVolH0eYfdAB9tm7ms0nHxAn605qYnYmjXkYjMvgEgi8_D9Amoupp0o11LAFMHxOzs4z6_iTbGxAnZLuDb_7a6fQGCP5er4Ko5jdoVI9UyfTKkzsv3mJisEdQ=w2408-h1806-no?authuser=0',
        text: 'Noen ganger smiler du fint. Andre ganger ikke.',
    },
    {
        id: 5,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEX3INFl9LGt4X9akgmkml7f4Xbwx9t1hv7_3KPYiegNEFYv4vp0Yhgrq0ynrB5YktT9RLNUEdVx9MzNWXQ4v89TWU6QlowLL27Up9G6O56xJffJRc776Kjl38cEOeaHpFNYgLFno0VhMDkrVaZPDPSlTw=w2408-h1806-no?authuser=0',
        text: 'Det er koslig å være på tur med deg!',
    },
    {
        id: 6,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEX6q7asTMmgdDSyD0LEvT0Q7qrauQrsGSNxRZfeIjm9UxopALhY-EZGNV-ShAsYKGsYaVN1IMGMGLvgNE8ACfieToX23KmcQdyx3uhjPlSumpbhLU3pRPhxcYcpQDGdc0oltG54czzKEt9VOa2Q2skX_w=w2408-h1806-no?authuser=0',
        text: 'Du er sporty! Jeg liker å gå på ski med deg!',
    },
    {
        id: 7,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEU1i519xaPlwxThketn_UC9NPCSrONQoNanW2iaAmwaYzh6CQWKyBo--2YXzfBzmmhUfm6-Eaxeg6BHDVOGE0Xi8tGSka8kr4tq238HsToVoGCRAb6cUN9-Mib3NSZadTwnEGmAsaIKKOyHKCHct12ueg=w1563-h1080-no?authuser=0',
        text: 'Du er flink til å svømme. Flaks for storebror at han svømmer litt kjappere selv om han ikke har samme teknikk som deg.',
    },
    {
        id: 8,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEVfcftnAJTEsRg0IzYNg1vwah1A6JBL0XOdtFfLFtUKyzqdQSpQVGBLevek5Dpt73E28cEE_rW2AmVAD1prDUc8dkfFovZ9fOvy3LIIO0MM1kTF3jZcTRqJisQHZnuczNYtTdfGGPJnzVjyjtjFaJ3wxw=w1356-h1806-no?authuser=0',
        text: 'Se på trioen!',
    },
    {
        id: 9,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEUqNJGN0nfC4dR-0y0qK2U8USO5x9vM98HiQ-DdjGUD6_VN89n_OVmuPameKVSJqVrfMtHLsDQinQ2UEQG8mkhfu4vZ_uuF4QEw_VfRObtVgK3QAiXSsvuBHJVWXndaSt0l9lciVLgkp8CRCqM4OVaFwA=w1356-h1806-no?authuser=0',
        text: 'Noen ganger er du litt tullete',
    },
    {
        id: 10,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEV4WeimwbPimNVLfryq2rFPO847KOQqvDfrOGFPKtRNLjtm2FXZi14fl8ELgPcPc5dcssNeGZvZnjM2jNRnZKZcFbo-op11-Fu94xNYOFtuTs-rY2GeXuUpuvOBJ5pYkHvrULsUk7EIQjo5t-M52URqLg=w1600-h900-no?authuser=0',
        text: 'Det er morsomt å henge med deg!',
    },
    {
        id: 11,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEX-dCe_IgFG2C28hrmx2dEJFnIgyxJUsK0XqVjUa75L9XvcvgmCmNxFZ2s3VNqigoOkzwQU8xwwdT6nBUqu3qdwEiipoAvKaWtYASNda2nYnDAvS-ONhuAo9UcDb_IzTNaQCeck4bcAYgAImnzCypI_EQ=w2408-h1806-no?authuser=0',
        text: 'Du er en kul klatrer!',
    },
    {
        id: 12,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEX5h7kjumwc4HtMf-tMacaP7vDaI147MmZhbaj3lJlKPjSI5sSNYdTGAhA7JUgPTuzG1M5MhUNBDMj6LH2uCICRbCUYvZO67Bdq3oRKwXyr-k7TVrZXfeAC6IjSUZmXT4PRL1_fAWpQcI9JQ4iKC-SYJA=w1125-h844-no?authuser=0',
        text: 'Se så fiiiiint!',
    },
    {
        id: 13,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEWg6rwUBlkvHtRKkZdoE-Pu85lR7t5wrLPXIa3ARrqEozlWU8YjKm5CzigyOaNePsYAY2rikKdqgfs6e36TpT8U0bijQH6MRSMTP1lzTfyFrcxERfGW3ivkgwyToWThPAMK42GS1wp38hNatRUEujEDGw=w2408-h1806-no?authuser=0',
        text: 'Jeg gleder meg til å gå på topptur med deg! Kanskje vi slipper å stake nedover denne gangen?',
    },
    {
        id: 14,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEXyJDZTwriG6LMJuvSvxIGB_iMlv2UZKZIq5B19a4eRspOFs-Tk1--4-AG_fJSpEBlaiET4WPxn7CfIERprq69qYdsTNbB2Ez9_v8i5Dq9zDt6V6JrofjLGFid5nnvGVH7FWng9jgAPsQs6Xj7tYUna3A=w2408-h1806-no?authuser=0',
        text: 'Se på den gjengen da!',
    },
    {
        id: 15,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEVIbfWaJJncDRR3FqVwcAZoWJHb8Gw5pXcs37C8lWy-nyV4ohbGVQkeOUuHq6AsM2qXoQoWcYkif9DnvDXoLifEfhEsDSL35R28Yqj-sPIESvBtM-6xh5nrWFSAlk2HIjGpbtnZ-xioPF8U0qHB9YW7og=w1356-h1806-no?authuser=0',
        text: 'En gang byttet du sykkelslange med oppvaskhansker, der er litt tullete.',
    },
    {
        id: 16,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEUxdxMtFWICepNIkoE_Gk5iK-8EhP41BANmhGx66gCc6UOu7mqPijxnUTB7aiQj2oHVYgdpbdfUKF4X4ST1jxnkRmXlqC4cSogStckfwcEhknP52gvOKDeONlsb3ks0H2hNqD9OCh9myUL4w6bD7wMHiw=w1080-h810-no?authuser=0',
        text: 'I dag synes jeg du skal ta deg noe godt å drikke, kanskje kokkosmelk?',
    },
    {
        id: 17,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEWkEKJMX_y762mofTXsPHVlOqBH0T0Q4-9DHJE1D60UydJ4rYHklI3Tl9AJ0e3Bm7XAX9m_Jyt8K0HoFriJPMIi6JfE-BQKb64lC0FaYjFnOCcKulwtBQJP6AlpQqR6qSJwZqpD-RXOKGU9-7dgSOCq3A=w2408-h1806-no?authuser=0',
        text: 'Du har noen kule grimaser!',
    },
    {
        id: 18,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEX_hm-_YxNSBAjhuVh0IIiyXbN887LqXNyTHkp__qTI_BgOPJeWw3qU7eyDgQIlE8r_8xBva5A8_QuCe89miecwCwFfy9kkJ6jh2JhWkQqbLLhoZjRZymJIfC56iwkKW2qoCK69BjWPfb4e60ZJxsSuTg=w2408-h1806-no?authuser=0',
        text: 'Du har mange kule grimaser!',
    },
    {
        id: 19,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEW2impOs9lPiI911D8JKlYnZiFh5Yqwyv3Z0vCEkniQsZ94zuJI9Xhoqfiad3lmD3-ThGwvdD5wszXm_mQNCRmB5L1TimQYKH4gLqNX5lGYxUlPjpdjpYIkzNUhFaWTHRqqLhHQ2pf3zi0xNI9sfQCuXg=w2408-h1806-no?authuser=0',
        text: 'Er det litt kaldt i dag? Bare å glede seg til sommeren!',
    },
    {
        id: 20,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEW1LSBc-0Jdp5xTaPLmLVrOK4Ril-YSoKOY8SrUgBHaGzjS-Kk0kmnIat2f-qd_rASRiPTPXKDltaYOtk0OTsTlZanFaIPVvrR5w6xO27qixYYFtBTqETwU7S5d48ENWClJ2XlnQQeCgJi-CWFGfh6Ixw=w1356-h1806-no?authuser=0',
        text: 'Det er godt at du passer på meg og Hallvard!',
    },

    {
        id: 21,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEWWI4CkkRMD8R798BG8Q7FFoZAIykqkXUxF0RlcgV1gbGLiP19tGUCwQweO1qnKjvFOxTvbk42d0ENTAOB3xoy2RdR_PuJE4rgeaU5LxpQnE69GqSr2oRwuBu13EO-S_l4Gu99bMcbU01wBhUzSog-e9w=w1016-h1806-no?authuser=0',
        text: 'Du lager god stemning!',
    },
    {
        id: 22,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEUIl41Kg-GymjefV7ZMSGZsY-J4jtIv7YDbjpw8es_26UBvn_2J3DGGKkAKNhYNqfBOIVA-6BRuJ6JOo4hD81uiWD6TdS4EjwhmyN57LamDWQKKmV_AKTXnwA1m-2lvgF7M7x77kcbsCmNZabPnKW8KBA=w2408-h1806-no?authuser=0',
        text: 'Se så fin gjeng vi har!',
    },
    {
        id: 23,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEXOQK9edOSoUS3RiA2hLTaJVWY9kujKTbWwEggYNw_1rlG6LLGCjLcwgfAFp7r_ztp5uhADchmYYmta2D5tFkopk4MQRSxCREo0PjwOmH-bJVVZMh3NScEqkcEfb0C3cqAnnRr6Xd3lxQVIeh-WWcc6wg=w1016-h1806-no?authuser=0',
        text: 'På tide å pynte til jul?',
    },
    {
        id: 24,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEWyJ0FHDFlXS5mL008squjsxYiLOi4KAMTEPtyNOsGYHI7AguRHnUQmVi910ut7uZGZqxbyjGUC1bVNIbIYJgj9_KBRFz6TlzdNGc4TCJyd3tr8tUcfB1JddG7o6zNk36zgc9qq3znpnCy71kktF6srHg=w2408-h1806-no?authuser=0',
        text: 'God jul! Jeg er glad i deg!',
    },
];

export const herborgLuker = [
    {
        id: 1,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEVFsoKzqikV0VGLzdRv8eDgcaAOzJ5Z3A9QwKF2A-v96voRwCqJLtuTdi4ls562cIvNRPiI7TFmfSEd09XIhz3y4E41YFwvCEchpP5lsV2dlhSnH-0HT2vqNu05cbp_Lf0CzzuknqP6U_AHaP3JC4eKrA=w3416-h1576-no?authuser=0',
        text: "Hei Herborg! Velkommen til Sigmund's julekalender. Her vil du finne en ny luke hver dag frem til julaften. Håper du liker det!",
    },
    {
        id: 2,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEX2KS7ZrQt8FR3XgKEwOesK7l3FSZghBdacVPIJ0agjLl2Mx50OuacsRK8kkzIaBGpvae-HQoW53FN8cDm6DyGaS6Kxa7LfaTv5OeI2hMRHKpvfpTntPLgyR_hqsGYT4xd8R45CRIn6EpP8O-yxbMbEMw=w2356-h1766-no?authuser=0',
        text: 'Du er fin å få på tur med! Jeg setter veldig pris på alle tingene du legger merke til og er opptatt av når vi er ute.',
    },
    {
        id: 3,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEWEag1cUz4TSgUgFiCXS8iSIQI6dce_j6ND5BAx43L8k6OZx1igIiqcCEQrpaNfrAu4o7Mam40niaK8URL-VGmaA5YkDxcXvBKOpa1jC5T0Gak8iqhGjN_0NSkhpQj4AYy97rYTkS-a_dg73-QTtxjZgA=w3416-h1574-no?authuser=0',
        text: 'Du er utrolig tøff! Jeg er glad for at du vil være med meg på mange ting som er litt skummelt.',
    },
    {
        id: 4,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEUroKq7IxYzC5vrrrzWcWadK0FHIZcOpDAla4nS4wSUKNTgde2e3fKaJN4fF-Ze_r2g88AKGVItaqkpqhfM3KosPSFquDOrRzPpQ60Ptxh6jbxcNwRTihI92YORRI3bmHhbmTrkRhmstlYdFWBBsh7gUQ=w405-h879-no?authuser=0',
        text: 'I dag er det søndag. Den perfekte dagen for å være hjemme og slappe av, og kanskje pleie Nikolai litt ekstra.',
    },
    {
        id: 5,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEWKm0dIB2iJJluxB6-YDuc_QzANLR_TLioxAgEPBjOWIhbHJpEtK-PWN7GdxcFBLm30tHIwZsXbLstmQlhVDh8DGDScSHGvMAMSvqd-1nXYTWdOlG51_OWmEnT48DeMufgb6tPNkS3mVSTRp-diPxRPqg=w1901-h879-no?authuser=0',
        text: 'Du er så flink til å lage mat! Jeg digger å spise maten du lager, både inne og ute.',
    },
    {
        id: 6,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEW2vjSjLj9tDbGjoIs1CTr7UdzWkLy1hIwlfrwE4noSfndZx0QKLTMP3Roen79QpEinmQJVH-_TPTyn_VzFKxaxZ_l7-_W_SKbZAeDHuIPYpC9tiONwX8yxp5JStvGn5rggB0dVPQ9CoFW7oge7sc0lLA=w1172-h880-no?authuser=0',
        text: 'Nå er vi godt inn i Desmber. Er det skiføre enda? Jeg gleder meg til å stå på ski med deg igjen!',
    },
    {
        id: 7,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEXTzF_K0-XM5DnfK7WCUebUEJBjk_R20skzXhQG0nYi8OdDShCiwa77-pE6z-Zq5qpSWCmK2oZdKhKhiIzCOgWnlml6KHhEHBNpi8pUg8xVxnSCLxbLNlmOfcH4UG-kN30H3LleP8ZzjZt1yLt5dEZFUw=w1170-h879-no?authuser=0',
        text: 'Gratulerer med dagen, Herborg! Jeg håper du får en fin dag med masse god mat og kos. Jeg veldig glad i deg!',
    },
    {
        id: 8,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEVbJrrl1XrZ5EpMQPBxtdWjCvzjXDhzhLKvdVGEfjkMpQddoCBFrPshfX5GntA8_By4e9VgnR6iz2Z8FJ92NObI_pywg1kq7py-6KfLujRduAMG1376qZgaNMIdvDnKNFzWgqwWtCAy8rMUGl3UFg7uMQ=w660-h879-no?authuser=0',
        text: 'Se så fin du er! Jeg liker deg ekstra godt når du pynter deg og gjør deg litt ekstra vakker.',
    },
    {
        id: 9,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEXAvQQ7d46Yu6lzXvA9zAMeRyWMfFZbCRiijGxnd9vxJJFs-F_uZ7XI30e_zc_urevVNfrFi-D-jV0vkG3-Hri29wPr7loenoTyA9Z8uSCr70sL3fkW32J2MBrip4U73yq-UiaDhHYwXlqWHHGCBCqaSA=w405-h879-no?authuser=0',
        text: 'Det er fredag! Håper vi skal spise noe digg, slik som denne sushien!',
    },
    {
        id: 10,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEUKE3gSQTMPLYlC6z7h6AwqkHojdy6ficG7DAsr5jyjebjDhcMUdb-449uO4d7gQTam3mrrEI1WDPeLOm5yOjP4HwDUCcse9q_0hEAdhp2byLMsmXIUftl1Eka4FSO853Cl89M9-vd-BO9A-IVfv9ROcw=w1909-h879-no?authuser=0',
        text: 'Jeg gleder meg til mange skiturer med deg i vinter!',
    },
    {
        id: 11,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEV0J6TzMuFS6ydNshwAYuDp1dQTvx92c9FNaziCsxcxxEkGx8kU5VtLkgNhrVWJ3Mas6R7IVdi1PhFVrOawX63c9zZotYQQlgq5bQSzcmX-9ZuUVyVD5BBmINARQcEq26X5inBUPE47uNBtogkCwO3Osg=w661-h879-no?authuser=0',
        text: 'I sommer håper jeg vi slappet masse av sammen. Ligge langflate på skjæret og gjøre ingenting sammen.',
    },
    {
        id: 12,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEWHSJl2yVK664h_nINhsucqUDrHYH8K98ySQ7eBgmaQFCeo77GF9AI78FZtZAhNt8kBTdeQQJZFJGwhd-57QzBD2tbgB1-_j121MBSKkSXGtCvOi1FUdVJ4CqSm_QLoVDoX5tePUt67guFqLdppXmYM2A=w1909-h879-no?authuser=0',
        text: 'Du er flink til å utfordre deg selv og prøve nye ting. Jeg er stolt av deg! Du har samme innstilling og samme hår som pippi.',
    },
    {
        id: 13,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEVMjDvZonEcSy9sDqpzjWoUSUfctBAoq_Sbn_UX9bmTuRwstCzfR8AV1HyacAjSSS9jN91VRke3Uy10RGAv1Z7AmKnjUD89gSzQIB5Xt_wgNGoXEI4Sy_933K5l8C8HtNvziJiDBU6mIrHRxkr7b4XTKw=w1908-h879-no?authuser=0',
        text: 'Jeg setter pris på alt du tar meg med på. Jeg liker godt når du forteller fra om ting du har gjort og opplevd.',
    },
    {
        id: 14,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEW7bwWoEcxvFCJlTayFlafdWyzorSGBKgVCUJxQu4ltXKflfrc6Pp0_PPkuOZLORH23xd64WEtffV2gy8ndMwnebZ5L8Bg27Yj_vu93a_saf5hgggzAYK2UIeozwv-nM8ogvj79TXkqBOhBnzxUZvk6tg=w1908-h879-no?authuser=0',
        text: 'Du er flink til å ta vare på og heie på de rundt det. Der er du et stort forbilde for meg.',
    },
    {
        id: 15,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEXac7pqVezVd0v-tv7wgQAWsNjuFKFx3rcbhSAT6T-q_6hs9FcLh1jzCKCyp51dj_5SLLK0GrnF7FovUfPlLN41YYV-2HO2RCjLNfvmfmxa-jvJlDgCVlPFcWk6IMiQ0Zeczg8ZACskjbNr-i4P1qhpmQ=w1909-h879-no?authuser=0',
        text: 'Du er super søt! Jeg liker godt når du er glad og smiler. Du er en av de beste tingene i mitt liv.',
    },
    {
        id: 16,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEU57SjWFa5ZoYHEHF4N8M0Epdhh-vbroRkYUkmigOh5SHc1ZvVkS4sp2ZvaNDQ1kzgtRMwjpbyd9YilEgzUJiiq-FON7CTKdSsCxBAz5KDFLqyS1OjziTpvDD3QYjmXQBdjHC8k2PzEotYBm_LU69WHow=w1568-h879-no?authuser=0',
        text: 'Jeg liker å utforske nye steder med deg. Jeg gleder meg til å utforske nye steder med deg i fremtiden.',
    },
    {
        id: 17,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEXzmGNfICPppFsCW_BosQunezC63qbA5SaxqpBU4X08dIW-3e8h9cUYLJdDReVVuVgj7o16TYGiAzjaENabTAT3F8Znq2iQVtrbYi3yUeaHKcz679psTuSg5MjrFWMEqjewDfDsGSHt72jgFmEMdAkuLw=w1170-h879-no?authuser=0',
        text: 'Jeg liker å klatre med deg. Om noen år kan vi klatre dete fjellet sammen.',
    },
    {
        id: 18,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEX288JYOBXxCJZSNSodePaqKa1xEOMLUZo4BhQ_DDbHTVx80YO2LPuMmEgSRTUWyxxuXOiLTO4h-UoqOLuwzZfCYLQndx2S-3oI1cB0fo2k2JHKZvm1tkLEqJAVG3iWrcIR1itPqEWtmktcu_P81BQSWA=w1172-h880-no?authuser=0',
        text: 'Husker du da vi var på sel-safari-padling? Det var så gøy! Jeg gleder meg til å gjøre det igjen.',
    },
    {
        id: 19,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEX81Mi9y0nhExekXlvL8zNNXccK7m0Ob6IY0EDKYJPhKQ5uNyMaLnMeTevdm9qkUJkpBQukHBbzp_t5dQ0CYqK3Xgewdku7qmQtw-vgwha6tNNGpxS5Qi95KkNiRX11NFSpZ5kXTQ0miIY8fB0NlGHsBg=w627-h836-no?authuser=0',
        text: 'Se på den hyggelige gjengen fra Alpene. Jeg syntes det var veldig spennende å flørte med deg på den turen.',
    },
    {
        id: 20,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEVBFETO83mXWQkcGSmfThTSeaQ5EQeyfuAOsms22g_f3JGlc1ijAiMdZimnagfliZX217xpWcNY609kDy5zg6JbryhvA66GBdGDzXruZ0oK5wMxxXSgJjWk8Tga3xg6fu008UaNAld5FbK2TDyfxAcDWg=w1563-h879-no?authuser=0',
        text: 'Jeg håper du vil spise masse godsaker med meg i 2023!',
    },

    {
        id: 21,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEUEgTnjeh-rFnKtCzUmEZMIWLrEgrmuToFaeGShn3zQdlxN6UeMhiMq3IRvXXOHasTjTZ77VSbcUvVyXCPgFmb3etm-IuP0vBzpT08T-urt91jlA2deYnJeWe_8-7KEBKH6n73e9SMKuegs5LlhUfeDxQ=w626-h836-no?authuser=0',
        text: 'Jeg syntes det er aller morsomst å dra ut når du er med! Da kan vi danse sammen!',
    },
    {
        id: 22,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEVCbe6P4zEtU4NAC2_IK_R7CmmRHv0QcOYPmwYGqn0uaetXiJdwVvZCclErvcwAwbJpoUV_eE7rue525YzX937rZP89OIHv46K4hKO0SDyUVhmF91N_h1DHnByApugGR2-s_N4Qjr5CEMlI_51qWAVQTw=w1170-h879-no?authuser=0',
        text: 'Jeg gleder meg til å se mer av Isfjorden og Romsdalen med deg! Det syntes jeg er så fint.',
    },
    {
        id: 23,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEWBK7CJECmLeSmNG7DhOmee2Tnkay7ZXOM2npF2f5SACuL252Z6FNdv6WJh-U25uNguWKReydO4mLVIkpNkHOR8Wjl-afPxVDuS8AAcXzvdnfu_cogLzEnuoFB1uAWSOVdbImKSssoqiYuIwgsvHFbq2w=w1909-h879-no?authuser=0',
        text: 'Du er kul ;)',
    },
    {
        id: 24,
        image: 'https://lh3.googleusercontent.com/pw/AL9nZEVBiTpERz2BD9Vqp7-KRlYIDYZ_6xAAD4J6FCq3KEStdOoQ5FmsHnC9DTA3A4erXxPuMTMjs-eVFhfa7Ylyp-nnvCGboLinaKOc1p2u3AEfnDWX3Q0cSbgjvbVNdFUzsWPUELbmHY_F4re1GYl60GC0_Q=w1908-h879-no?authuser=0',
        text: 'God jul! Jeg er glad i deg! Gleder meg til å se deg om bare noen dager.',
    },
];
